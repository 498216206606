"use client";
import "@styles/globals.css";
import QueryProvider from "./QueryProvider";
import ReduxProvider from "./ReduxProvider";
import { GoogleAnalytics } from "@next/third-parties/google";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import ExternalBrowser from "@components/redirect";
import TagManager from 'react-gtm-module';
import { GoogleTagManager } from '@next/third-parties/google'


const AdjustInitializer = dynamic(() => import("@hooks/useAdjust"), {
  ssr: false,
});

const RootLayout = ({ children }) => {
  const [links, setLinks] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinks(window.location);
    }
  }, []);



  return (
    <html lang="en">
      <ReduxProvider>
        <QueryProvider>
          <AdjustInitializer />
          {links && (
            <ExternalBrowser child={children} links={links}>
              <body>{children}</body>
            </ExternalBrowser>
          )}
          <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TCGVZNBP"
height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
                <GoogleTagManager gtmId="GTM-TCGVZNBP" />
          <GoogleAnalytics gaId="G-5E07BFZDQN" /> 
        </QueryProvider>
      </ReduxProvider>
    </html>
  );
};

export default RootLayout;
